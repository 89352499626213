<template>
  <div class="invoice_son_box">
    <headSearch ref="headSearch" :search-out="headSearch" />
    <div>
      <div class="invoice_box_list top">
        <div class="invoice_box_title">
          <i class="invoice_box_l">抬头类型</i>
          <em
            v-for="(item,index) in tabList"
            :key="index"
            :class="isIndex==item.id ? 'em':''"
            @click="isIndex=item.id;tab()"
          >
            {{ item.name }}
          </em>
        </div>
      </div>

      <div class="invoice_box_list">
        <div class="invoice_box_title">
          <i class="invoice_box_l">发票抬头：{{ this.$store.state.title }}</i>
          <img src="../../assets/images/MoreIocn.png" alt="" @click="linkTo(1)">
        </div>
      </div>
      <div v-show="isIndex==1" class="invoice_box_list">
        <div class="invoice_box_title">
          <i class="invoice_box_l">收纳人识别号：{{ this.$store.state.number }} </i>
          <img src="../../assets/images/MoreIocn.png" alt="" @click="linkTo(2)">
        </div>
      </div>

    </div>
    <div class="invoice_btn" @click="getSave()">完成</div>
  </div>

</template>

<script>
import headSearch from '@/component/head/search'
import { save } from '@/services/api'

export default {
  name: 'AddInvoice',
  components: {
    headSearch
  },
  data() {
    return {
      headSearch: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: '编辑信息',
        callbackGo: this.callbackGo // 回调
      },
      // isIndex: this.$route.query.titleType || 2,  // 默认个人
      isIndex: this.$store.state.titleType || 2, // 默认个人

      tabList: [
        { id: 1, name: '公司' },
        { id: 2, name: '个人' }
      ]
    }
  },
  mounted() {
    // this.getSave()
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    tab() {
      this.$store.commit('isTitleType', this.$store.state.titleType)
    },

    // 修改
    linkTo(index) {
      if (index === 1) {
        this.$store.commit('isTitle', this.$store.state.title)
      } else if (index === 2) {
        this.$store.commit('isNumber', this.$store.state.number)
      }
      this.$router.push({
        path: '/invoiceTest',
        query: {
          type: index
        }
      })
    },

    // 提交
    getSave: function() {
      const data = {
        titleType: this.isIndex, // 抬头类型 1:公司 2:个人
        number: this.$store.state.number, // 税号
        title: this.$store.state.title, // 发票抬头
        id: this.$store.state.nubId
      }
      save(data).then(res => {
        if (Number(res.code) === 200) {
          this.$router.push({
            path: '/invoiceSon'
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.invoice_btn {
  width: 100%;
  height: 44px;
  background: red;
  position: absolute;
  bottom: 0;
  text-align: center;
  line-height: 44px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.invoice_box_list {
  width: 100%;
  height: 42px;
  background: #fff;

  .invoice_box_title {
    height: 42px;
    margin: 0 0 0 12px;
    line-height: 42px;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .invoice_box_l {
      float: left;
      //width: 100px;
      text-align: left;
    }

    .invoice_box_l {
      float: left;
    }

    span {
      padding: 2px 6px;
      border: 1px solid rgb(248, 7, 7);
      border-radius: 4px;
      color: rgb(214, 15, 15);
      font-size: 13px;
    }

    em {
      padding: 2px 6px;
      border: 1px solid rgba(0, 0, 0, .3);
      border-radius: 4px;
      color: rgba(0, 0, 0, .3);
      font-size: 13px;
      font-style: normal;
      margin-right: 12px;
    }

    em.em {
      border: 1px solid rgb(248, 7, 7);
      color: rgb(214, 15, 15);
    }

    img {
      float: right;
      width: 7px;
      margin-top: 13px;
      margin-right: 12px;
    }

    i {
      font-style: normal;
      font-size: 14px;
    }

    .span {
      color: #666;
      font-size: 13px;
      border: none;
    }
  }

  .no {
    border: none;
  }
}
</style>
